import logo from './logo.svg';
import './App.css';
import BaseRoutes from './Routes';

function App() {
  return (
    <div className="App">
      <img style={{ position: "absolute", top: "20px", right: "40px"}} width='5%' src='./cloudflare.svg' />

      <BaseRoutes />
    </div>
  );
}

export default App;
