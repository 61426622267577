import React from 'react';
import OrgChart from '@unicef/react-org-chart';
import avatarPersonnel from './avatar.svg';


export default class OrgChartDisplay extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			downloadingChart: false,
			config: {},
			highlightPostNumbers: [1],
			loading: true,
			organization: {},
		};
	}

	handleDownload = () => {
		this.setState({
			downloadingChart: false
		});
	};

	handleOnChangeConfig = config => {
		this.setState({
			config: config
		});
	};

	handleLoadConfig = () => {
		const {
			config
		} = this.state;
		return config;
	};

	componentDidMount() {
		const getOrg = async () => {
			const resp = await fetch('/organization-chart');
			const orgResp = await resp.json();
		
			this.setState({loading: false, organization: orgResp});
		};

		getOrg();
	}
	

	render() {
		const {loading, organization} = this.state;

		let managers = [];
		let employeesDep = {};
		let company = {};
		if (!loading) {
			company = {
				id: -1,
				person: {
					id: -1,
					name: "Cloudflare",
					department: "Cloudflare",
					totalReports: managers.length,
					avatar: avatarPersonnel,
					title: "Whole Company"
				},
				hasParent: false,
				hasChildren: true,
				children: []
			};

			let departments = organization.organization.departments;

			let id = 1;
			for (let i = 0; i < departments.length; i++) {
				let manager = {
					id: id,
					person: {
						id: id,
						avatar: avatarPersonnel,
						department: departments[i].name,
						name: departments[i].managerName,
						title: departments[i].name + " Manager",
						totalReports: departments[i].employees.length - 1
					},
					hasParent: true,
					hasChildren: true,
					children: [],
				};

				id++;

				let employees = [];
				for (let j = 0; j < departments[i].employees.length; j++) {
					if (departments[i].employees[j].isManager) continue;

					let employee = {
						id: id,
						person: {
							id: id,
							avatar: avatarPersonnel,
							department: departments[i].name,
							name: departments[i].employees[j].name,
							title: departments[i].name + " Employee",
							totalReports: 0
						},
						hasParent: true,
						hasChildren: true,
						children: []
					}
					employees.push(employee);
					id++;
				}

				manager.children = employees;
				managers.push(manager);

				employeesDep[departments[i].name] = employees;
			}

			company.children = managers;
		}

		let content;

		if (!loading) {
			const goToTop = () => {
				window.scrollTo({
					top: 0,
					behavior: "smooth",
				});
			};
			content = (
			<React.Fragment >
			<h1 style={{marginTop: "60px"}}>Interactive Organization Chart</h1>
			<div style={{marginLeft: '20%', marginRight: '20%', textAlign: 'left'}}>
				<h2>How to Use:</h2>

				<ul>
					<li>Click on each superviser to expand/collapse the tree</li>
					<li>Use the mouse scroll wheel to zoom in and out</li>
					<li>Use the up arrow button to scroll back to the top of this page</li>
				</ul>
			</div>
			<button
                className="btn btn-outline-primary zoom-button" onClick={goToTop} style={{justifyContent: "center", alignContent: "center"}}>
			<svg xmlns="http://www.w3.org/2000/svg" height="2em" viewBox="0 0 384 512"><path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"/></svg>
			</button>
			<OrgChart tree = {
				company
			}
			onConfigChange = {
				config => {
					this.handleOnChangeConfig(config);
				}
			}
			loadConfig = {
				d => {
					let configuration = this.handleLoadConfig(d);
					if (configuration) {
						return configuration;
					}
				}
			}
			loadImage = {
				d => {
					return Promise.resolve(() => avatarPersonnel);
				}
			}
			loadParent = {
				d => {
					return d;
				}
			}
			loadChildren = {
				d => {
					if (d.hasChildren) {
						return employeesDep[d.person.department];
					}
					return [];
				}
			}
			/> 
            </React.Fragment>
			);
		}
		else {
			content = (
				<React.Fragment>
					<h1 style={{marginTop: "60px"}}>Interactive Organization Chart</h1>
					<div>Loading...</div>
				</React.Fragment>
			)
		}
		return (
			<div>{content}</div>
		);
	}
}

// const [organization, setOrganization] = useState([]);
// const [loading, setLoading] = useState(true);
// useEffect(() => {
// const getOrg = async () => {
// 	const resp = await fetch('/organization-chart');
// 	const orgResp = await resp.json();

// 	setOrganization(orgResp);

// 	let departments = organization.departments;

// 	setTree(tree);
// 	setLoading(false);
// };

// 	getOrg();
// }, []);