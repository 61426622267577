import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import OrgChartDisplay from './pages/orgchart';
import React from 'react';

const Features = () => {
    return (
        <React.Fragment>
            <h1 style={{marginTop: "60px"}}>Organization Chart App</h1>
            <div style={{marginLeft: '20%', marginRight: '20%', textAlign: 'left'}}>
                <h2>Features: </h2>

                <ul>
                <li>GET <a href='/organization-chart'>/organization-chart</a> - Returns the current pre-configured organization graph structure</li>
                <li>POST <a href='/organization-chart'>/organization-chart</a> - Organizes request body's CSV data into an organization object</li>
                <li>GET <a href='/me'>/me</a> - Returns some cool information about me!</li>
                <li>POST <a href='/employee'>/employee</a> - Filters the list of employees based on given characteristics</li>
                <li>GET <a href='/orgchart'>/orgchart</a> - Displays an interactive chart of the organization at Cloudflare!</li>
                </ul>

                <p style={{marginTop: "20px"}}>
                    Dear Cloudflare Hiring Team, 
                    
                    <br/><br/>

                    Thank you so much for giving me the opportunity to be guided through creating a web app similiar to 
                    what interns are building in the company. It has provided me with great insight into the work done 
                    at the organization and an awesome learning experience with integrating web applications with Cloudflare
                    products. I have gotten to polish up my web development skills greatly with React.JS with this project. 
                    I hope you all enjoy this website, and thank you for taking time to consider my application!

                    <br/><br/>

                    Sincerely,

                    <br/>

                    Qi Wang
                </p>
            </div>
        </React.Fragment>
    )
};

const BaseRoutes = () => {
    return (
        <Router>
            <Routes>
                <Route path='/' element={ <Features /> } />
                <Route path='/orgchart' element={<OrgChartDisplay />} />
            </Routes>
        </Router>
    )
};

export default BaseRoutes;